import React from "react";
import ProductList from "../../components/ProductList";
import IntroCard from "../../components/IntroCard";
import ProductSearchForm from "../../components/ProductSearchForm";
import VegNonVegToggle from "../../components/VegNonVegToggle";
import DeliveryChange from "../../components/DeliveryChange";
import { useCart } from "../../context/cartContext";
import CartSlider from "../../components/CartSlider";

const Home = () => {
  const { isCartSliderOpen, setIsCartOpen } = useCart();

  const handleCartSliderClose = () => {
    setIsCartOpen(!isCartSliderOpen);
  };

  return (
    <div className="pt-20">
      {/* Render your product list here */}
      {/* <h1> Order your favourite snacks at the comfort of your home </h1> */}
      <IntroCard />
      <DeliveryChange />
      <CartSlider
        isCartOpen={isCartSliderOpen}
        onClose={handleCartSliderClose}
      />
      <div className="container-lg flex">
        <div className="mx-auto w-8/12">
          <div className="flex w-full py-4 flex-row-reverse">
            <div className="w-full md:w-2/6">
              <ProductSearchForm />
            </div>
            <div className="w-full md:w-4/6">
              <VegNonVegToggle />
            </div>
          </div>
          <div className="flex flex-wrap flex-row justify-center mb-20  border border-gray-400 bg-slate-100 sticky top-0">
            <ProductList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
