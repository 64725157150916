import { Drawer, Button, Card } from "flowbite-react";
import { useCart } from "../../context/cartContext";

const CartIcon = () => (
  <svg
    class="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"
    />
  </svg>
);

const CartCard = ({ item }) => {
  const { increaseQuantity, decreaseQuantity } = useCart();
  return (
    <Card
      className="w-full  md:h-auto max-w-sm mx-auto object-cover my-2 bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:shadow-xl dark:text-white"
      horizontal
    >
      <img
        class=" w-full rounded-t-lg h-96 md:h-auto md:w-56 md:rounded-none md:rounded-s-lg"
        src={item.image}
        alt={item.title}
        width={40}
        height={40}
      />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {item.title}
        </h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          hot and crispy {item.title}
        </p>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Price: Rs. {item.price}
        </p>
        <br />
        <div className="flex justify-center py-4">
          <Button
            pill={true}
            color="success"
            size="sm"
            onClick={() => increaseQuantity(item)}
          >
            +
          </Button>
          <span className="px-2">{item.quantity}</span>
          <Button
            pill={true}
            color="failure"
            size="sm"
            onClick={() => decreaseQuantity(item)}
          >
            -
          </Button>
        </div>
      </div>
    </Card>
  );
};

const CartItems = () => {
  const { cart } = useCart();
  const cartList = Object.values(cart);
  return (
    <>
      {cartList.length ? (
        <div className="w-full  flex flex-col md:flex-row flex-wrap justify-evenly overflow-y-auto">
          {cartList.map((item) => (
            <CartCard key={item.id} item={item} />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default function CartSlider({ isCartOpen, onClose }) {
  const handleClose = () => onClose();

  const { cart } = useCart();
  const cartList = Object.values(cart);
  return (
    <>
      <Drawer open={isCartOpen} onClose={handleClose} backdrop={true}>
        <Drawer.Header
          title={`Cart (${cartList.length})`}
          titleIcon={CartIcon}
        ></Drawer.Header>
        <Drawer.Items>
          {/* Cart Items */}
          {cartList.length === 0 ? (
            <p className="mb-6 text-sm text-gray-500 dark:text-gray-400">
              Your cart is empty. Add some items to get started
            </p>
          ) : null}

          <CartItems />
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold text-gray-900 dark:text-white">
              Total
            </p>
            <p className="text-lg font-semibold text-gray-900 dark:text-white">
              {/* Calculate total price of all items in the cart */}
              Rs. {cartList.reduce((a, b) => a + b.price * b.quantity, 0)}
            </p>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Button
              className="w-full px-4 py-2 text-white bg-black rounded-md"
              onClick={handleClose}
            >
              Checkout
            </Button>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
