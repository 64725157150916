import React, { useState } from "react";

const VegNonVegToggle = () => {
  const [isVeg, setIsVeg] = useState(true);

  const handleToggle = () => {
    setIsVeg(!isVeg);
  };

  return (
    <div className="flex items-center">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={isVeg}
          onChange={handleToggle}
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-black dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {isVeg ? "Veg" : "Non-Veg"}
        </span>
      </label>
    </div>
  );
};

export default VegNonVegToggle;
