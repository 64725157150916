import { Navbar, Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { useCart } from "../../context/cartContext";

function BottomNav() {
  const { cart, cartTotal } = useCart();
  const cartList = Object.values(cart);

  return (
    <Navbar
      fluid
      rounded
      className="bg-gray-100 z-20 dark:bg-gray-800 bottom-0 fixed w-full border-black"
    >
      <h1 className="flex">
        <b>Your Cart</b>
      </h1>
      {cartList.length > 0 ? (
        <p>
          <strong>{cartList.length}</strong> Items{" "}
        </p>
      ) : (
        <p>Your cart is empty.</p>
      )}

      <p>
        {" "}
        Total Cost : <strong> Rs. {cartTotal} </strong>
      </p>

      <Link to="/cart">
        <Button className="bg-black">
          {" "}
          <svg
            class="w-6 h-6 text-white dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"
            />
          </svg>{" "}
          View Cart{" "}
        </Button>
      </Link>
    </Navbar>
  );
}
export default BottomNav;
