import React, { useState } from "react";
import { useCart } from "../../context/cartContext";
import { Button } from "flowbite-react";

const Checkout = () => {
  const { cart, cartTotal } = useCart();
  const cartList = Object.values(cart);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");

  const handleSaveAddress = () => {
    // Save address logic here
  };

  return (
    <div className="container mx-auto pt-20">
      <h1 className="text-2xl tracking-tight py-4 text-gray-900 dark:text-white text-center">
        Checkout
      </h1>

      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/3 p-4 mx-auto">
          <h2 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Update Delivery Address Details
          </h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Full Name
              </label>
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email Address
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Phone Number
              </label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Select Apartment
              </label>
              <select
                value={apartment}
                onChange={(e) => setApartment(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              >
                <option value="">Select Apartment</option>
                <option value="GR Lavender Apartment">
                  GR Lavender Apartment
                </option>
                <option value="Bangalore">Bangalore</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Door/Flat No.
              </label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            </div>

            <Button
              color="success"
              size="md"
              className="w-full"
              onClick={handleSaveAddress}
            >
              Save Address
            </Button>
          </form>
        </div>

        <div className="w-full md:w-1/3 p-4 mx-auto">
          <h2 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Place Order
          </h2>
          <div className="m-4 p-4 border border-gray-200 bg-white dark:bg-gray-800 rounded-lg shadow">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Order Summary
            </h1>
            <div className="justify-center">
              <p className="text-center text-gray-500 dark:text-gray-200">
                ({cartList.length} {cartList.length > 1 ? "items" : "item"})
              </p>
              <br />
              {cartList.map((item) => (
                <div key={item.id} className="flex justify-between">
                  <p>
                    {item.title} x {item.quantity}
                  </p>
                  <b>Rs. {item.price * item.quantity}</b>
                </div>
              ))}
              <br />
              <hr className="h-px my-8 bg-gray-800 border border-black dark:bg-gray-800" />
              <div className="flex justify-between">
                <p>Total Price :</p>
                <b>Rs. {cartTotal}</b>
              </div>
            </div>
          </div>

          <form className="space-y-4  p-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Payment Method
              </label>
              <select className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                <option value="UPI">Pay via UPI</option>
                <option value="COD">Cash on Delivery</option>
                <option value="Card">Pay via Card</option>
              </select>
            </div>
            <Button color="success" size="md" className="w-full">
              Pay Now
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
