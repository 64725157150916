import React from "react";
import CartComponent from "../../components/Cart";

const CartPage = () => {
  return (
    <div className="container mx-auto pt-20">
      <h1 className="text-2xl tracking-tight py-4 text-gray-900 dark:text-white text-cester">
        Your Cart
      </h1>
      <CartComponent />
    </div>
  );
};

export default CartPage;
