import React from "react";
import { Button, Card } from "flowbite-react";
import ProductRating from "../ProductRating";
// import AddToCartButton from "../AddToCartButton";

const ProductCard = ({ product }) => {
  return (
    <Card href="#" className="max-w-sm">
      <img
        src={product.img}
        alt={product.title}
        width={200}
        height={100}
        className="object-cover w-full h-40"
      />
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {product.title}
      </h5>
      <p>
        Price: <strong>Rs. {product.price}</strong>{" "}
      </p>

      <ProductRating rating={product.rating} maxRating={5} size={1} />
      {/* <AddToCartButton product={product} /> */}
      <Button color="success" size="md" className="w-full">
        {" "}
        Choose this{" "}
      </Button>
    </Card>
  );
};

export default ProductCard;
