import React from "react";
import ProductCard from "../ProductCard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProductModal } from "../ProductModal";
import CartSlider from "../CartSlider";

const ProductList = () => {
  const [openProductModal, setOpenProductModal] = React.useState(false);
  const [product, setProduct] = React.useState(null);

  // Dummy product data
  const products = [
    {
      id: 1,
      title: "Samosa",
      category: "Snacks",
      sub_category: "Samosa",
      veg: true,
      price: 25,
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDxY6fzSWJoTDb2-_SimvbgbDgMIHUHfKY3wVRqoCD&s",
      rating: 3,
    },
    {
      id: 2,
      title: "Aloo Bonda",
      category: "Snacks",
      sub_category: "Bonda",
      veg: true,
      price: 25,
      img: "https://www.archanaskitchen.com/images/archanaskitchen/1-Author/Madhuli_Ajay/Aloo_Bonda_Deep_fried_snack_made_with_potato_filling_and_gram_flour_batter.jpg",
      rating: 4,
    },
    {
      id: 3,
      title: "Veg Cutlet",
      category: "Snacks",
      sub_category: "Cutlet",
      veg: true,
      price: 30,
      img: "https://www.whiskaffair.com/wp-content/uploads/2020/09/Veg-Cutlet-2-3-500x500.jpg",
      rating: 5,
    },
    {
      id: 4,
      title: "Paneer Cutlet",
      category: "Snacks",
      sub_category: "Cutlet",
      veg: true,
      price: 35,
      img: "https://i0.wp.com/www.blissofcooking.com/wp-content/uploads/2017/07/Paneer-and-Vegetable-Cutlet-MOP.jpg?resize=740%2C493&ssl=1",
      rating: 3,
    },
    {
      id: 5,
      title: "Cheese Cutlet",
      category: "Snacks",
      sub_category: "Cutlet",
      veg: true,
      price: 40,
      img: "https://www.merisaheli.com/wp-content/uploads/2021/01/vermicelli-potato-cutlet-sevai-1-1-500x500-1.jpg",
      rating: 4,
    },
    {
      id: 6,
      title: "Bread Pakoda",
      category: "Snacks",
      sub_category: "Pakoda",
      veg: true,
      price: 30,
      img: "https://media.dinedelicious.in/wp-content/uploads/2021/07/Bread-Pakora-7.jpg",
      rating: 2,
    },
    {
      id: 7,
      title: "Chicken Cutlet",
      category: "Snacks",
      sub_category: "Cutlet",
      veg: false,
      price: 50,
      img: "https://www.thepetitecook.com/wp-content/uploads/2020/07/italian-breaded-chicken-cotolette-recipe.jpg",
      rating: 4,
    },
  ];

  const handleModalClose = () => {
    setOpenProductModal(false);
  };

  const handleProductCardClick = (product) => {
    if (product) {
      setProduct(product);
    }
    setOpenProductModal(!openProductModal);
  };

  return (
    <div className="max-w-lg min-w-fit sticky top-0">
      <ToastContainer />
      <CartSlider isOpen={true} />
      <div className="flex flex-wrap flex-row justify-center mb-20 overflow-y-auto">
        <div className="m-4">
          <ProductModal
            product={product}
            open={openProductModal}
            setOpen={handleModalClose}
          />
        </div>

        {products.map((product) => (
          <div
            key={product.id}
            className="m-4"
            onClick={() => handleProductCardClick(product)}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
