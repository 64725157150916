import "./App.css";
import { Outlet, useLocation } from "react-router-dom";
import TopNav from "./components/TopNav/TopNav";
import BottomNav from "./components/BottomNav";

function App() {
  const location = useLocation();

  const dontDisplayRoutes = ["/cart", "/checkout"];

  return (
    <div className="h-full scroll-smooth focus:scroll-auto App">
      <TopNav />
      <Outlet />
      {/* if route is cart, hide bottom nav */}
      {dontDisplayRoutes.includes(location.pathname) ? null : <BottomNav />}
    </div>
  );
}

export default App;
