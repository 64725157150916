const IntroCard = () => {
  return (
    <div>
      <h2 className="font-bold"> Delicious Food, Delivered To You </h2>
      <p>
        {" "}
        Choose your favorite snacks from our broad selection of available
        dishes. Enjoy at home
      </p>
      <p>
        {" "}
        All our dishes are cooked with high-quality ingredients, just-in-time
        and of course by experienced chefs{" "}
      </p>
      <p>Order now to get the delivery by Sunday 30, June</p>
    </div>
  );
};

export default IntroCard;
