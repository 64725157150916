import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import classNames from "classnames";
import "./ProductRating.css";

function ProductRating({ rating, maxRating, size = 1 }) {
  return (
    <div className="rating flex justify-start">
      <p>Rating: {rating}/5 </p>
      {new Array(maxRating).fill(0).map((_, index) => {
        const isActive = rating >= index + 1;
        return (
          <Icon
            key={index}
            path={mdiStar}
            className={classNames("rating_star", {
              "rating__star--active": isActive,
            })}
            size={size}
            horizontal
            vertical
            rotate={90}
          />
        );
      })}
    </div>
  );
}

export default ProductRating;
