import { createContext, useState, useContext } from "react";

const CartContext = createContext({
  cart: {},
  increaseQuantity: () => {},
  decreaseQuantity: () => {},
  isCartSliderOpen: false,
});

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({});
  const [isCartOpen, setIsCartOpen] = useState(false);

  function increaseQuantity(product) {
    const newCart = { ...cart };
    if (!newCart[product.id]) {
      newCart[product.id] = {
        id: product.id,
        title: product.title,
        price: product.price,
        quantity: 0,
        image: product.img,
      };
    }
    newCart[product.id].quantity += 1;
    setCart(newCart);
    setIsCartOpen(true);
  }

  function decreaseQuantity(product) {
    const newCart = { ...cart };
    if (!newCart[product.id]) return;

    if (newCart[product.id] && newCart[product.id].quantity > 0) {
      newCart[product.id].quantity -= 1;
    }

    if (newCart[product.id].quantity <= 0) {
      delete newCart[product.id];
    }
    setCart(newCart);
  }

  function getTotal() {
    let totalPrice = 0;
    Object.values(cart).forEach((cartItem) => {
      totalPrice += cartItem.quantity * cartItem.price;
    });
    // setCart({ ...cart, cartTotal: totalPrice });
    return totalPrice;
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        increaseQuantity,
        decreaseQuantity,
        cartTotal: getTotal(),
        isCartSliderOpen: isCartOpen,
        setIsCartOpen,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart };
