import React from "react";
import { Button, Modal } from "flowbite-react";
import ProductRating from "../ProductRating";
import { useCart } from "../../context/cartContext";

export function ProductModal({ product, open, setOpen }) {
  console.log("product opened >>>>", product);
  const { increaseQuantity } = useCart();

  if (!product) {
    return null;
  }
  const handleModalClose = () => {
    setOpen(false);
  };

  const handleAddItem = () => {
    console.log("Add item to cart");
    // close the modal
    setOpen(false);
    // add item to cart
    increaseQuantity(product);
  };

  return (
    <>
      {/* <Button onClick={() => setOpenModal(true)}>Toggle modal</Button>  setOpenModal(false)} */}
      <Modal show={open} onClose={handleModalClose}>
        <Modal.Header>
          {" "}
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {product.title}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="flex space-x-4">
            <div className="flex flex-col">
              <img
                src={product.img}
                alt={product.title}
                width={300}
                height={200}
              />
            </div>
            <div className="flex flex-col space-x-4 space-y-4">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                Enjoy hot and delicious {product.title} at the comfort of your
                home
              </p>
              <p>Veg : {product.veg ? "Yes" : "No"} </p>
              <p>Category : {product.category} </p>
              <p>Sub Category : {product.sub_category} </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex">
          <div className="flex-auto flex space-x-8">
            <p>
              {" "}
              Price : <strong>Rs.{product.price}</strong>{" "}
            </p>

            <ProductRating rating={product.rating} maxRating={5} size={1} />
          </div>
          <div className="flex-none flex items-end">
            {/* <AddToCartButton product={product} /> */}
            <Button
              color="success"
              size="md"
              className="w-full"
              onClick={handleAddItem}
            >
              {" "}
              Add to Cart{" "}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
