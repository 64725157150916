import React, { useState } from "react";
import { Button } from "flowbite-react";

const DeliveryChange = ({ initialAddress }) => {
  const [address, setAddress] = useState(initialAddress);

  // const handleChangeAddress = (event) => {
  //   setAddress(event.target.value);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle the submission logic here
  // };

  return (
    <div>
      <h2>Delivery Address</h2>
      <div className="border border-gray-200 bg-gray-100 rounded-lg p-2 m-4 flex flex-row justify-center items-center space-x-2 mx-auto w-2/3">
        <div className="flex flex-1 flex-start text-left space-x-2">
          <h1 className="font-bold"> Delivery </h1>{" "}
          <span className="px-2 font-semibold">|</span>
          <p> Delivery to: </p>
          <p> Tower-8, GR Lavender, Aradhna Layout, Arekere </p>
        </div>
        <div className="py-2">
          <Button size="sm" color="dark">
            {" "}
            Change Address{" "}
          </Button>
        </div>
      </div>
      {/* <form onSubmit={handleSubmit}>
        <label htmlFor="address">Address:</label>
        <input
          type="text"
          id="address"
          value={address}
          onChange={handleChangeAddress}
        />
        <button type="submit">Change Address</button>
      </form> */}
    </div>
  );
};

export default DeliveryChange;
