import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import Home from "./usingRedux/views/Home/Home";
// import HomeUsingContext from './views/HomeUsingContext/HomeUsingContext';
import Home from "./views/Home";
import CartPage from "./views/Cart/Cart";
import Checkout from "./views/Checkout/Checkout";
import Blog from "./views/Blog/Blog";
import Login from "./views/Auth/Login";
import Register from "./views/Auth/Register";
import UserDashboard from "./views/UserDashboard/UserDashboard";
import UserOrders from "./views/UserDashboard/UserOrders";
import AdminDashboard from "./views/AdminDashboard/AdminDashboard";
import AllBlogPosts from "./views/AdminDashboard/ManageBlog/AllBlogPosts";
import AllOrders from "./views/AdminDashboard/ManageOrders/AllOrders";
import AllProducts from "./views/AdminDashboard/ManageProducts/AllProducts";
import AllTestimonials from "./views/AdminDashboard/ManageTestimonials/AllTestimonials";
import { CartProvider } from "./context/cartContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "cart",
        element: <CartPage />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      // {
      //   path: "/context",
      //   element: <HomeUsingContext />
      // },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
    ],
  },
  {
    path: "/user",
    element: <App />,
    children: [
      {
        path: "",
        element: <UserDashboard />,
      },
      {
        path: "orders",
        element: <UserOrders />,
      },
    ],
  },
  {
    path: "/admin",
    element: <App />,
    children: [
      {
        path: "",
        element: <AdminDashboard />,
      },
      {
        path: "manage-blog",
        element: <AllBlogPosts />,
      },
      {
        path: "manage-orders",
        element: <AllOrders />,
      },
      {
        path: "manage-products",
        element: <AllProducts />,
      },
      {
        path: "manage-testimonials",
        element: <AllTestimonials />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CartProvider>
      <RouterProvider router={router} />
    </CartProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
