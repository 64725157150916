import React from "react";
import { useCart } from "../../context/cartContext";
import { Button, Card } from "flowbite-react";
import { Link } from "react-router-dom";

const CartCard = ({ item }) => {
  const { increaseQuantity, decreaseQuantity } = useCart();
  return (
    <Card
      className="w-full  md:h-auto max-w-sm mx-auto object-cover my-2 bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:shadow-xl dark:text-white"
      imgSrc={item.image}
      imgAlt={item.title}
      width={100}
      height={100}
      horizontal
      // className="w-full md:w-2/6 m-2 flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      {/* <img
        class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-56 md:rounded-none md:rounded-s-lg"
        src={item.image}
        alt={item.title}
        width={100}
        height={100}
      /> */}
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {item.title}
        </h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          hot and crispy {item.title}
        </p>
        Price: Rs. {item.price}
        <br />
        <div className="flex justify-center py-4">
          <Button
            pill={true}
            color="success"
            size="sm"
            onClick={() => increaseQuantity(item)}
          >
            +
          </Button>
          <span className="px-2">{item.quantity}</span>
          <Button
            pill={true}
            color="failure"
            size="sm"
            onClick={() => decreaseQuantity(item)}
          >
            -
          </Button>
        </div>
      </div>
    </Card>
  );
};

function CartComponent() {
  const { cart } = useCart();
  const cartList = Object.values(cart);

  return (
    <div className="flex flex-col md:flex-row">
      {cartList.length === 0 ? (
        <div className="w-4/6">
          <div className="flex flex-col items-center justify-center h-96">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Your cart is empty
            </h1>
            <p> Add some items to get started</p>
            <Button color="dark" size="md" className="mt-4">
              {" "}
              Add Items
            </Button>
          </div>
        </div>
      ) : null}

      {/* Cart List */}
      {cartList.length ? (
        <div className="w-full md:w-4/6 flex flex-col md:flex-row flex-wrap justify-evenly overflow-y-auto">
          {cartList.map((item) => (
            <CartCard key={item.id} item={item} />
          ))}
        </div>
      ) : null}
      {/* Price Details */}
      <div className="w-full md:w-2/6 lg:w-1/4">
        <Card className="m-4">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
            Price Details
          </h1>
          <div className=" justify-center">
            <p className="text-center text-gray-500 dark:text-gray-200">
              ({cartList.length} {cartList.length > 1 ? "items" : "item"})
            </p>
            <br />
            {cartList.map((item) => (
              <div key={item.id} className="flex justify-between">
                <p>
                  {item.title} x {item.quantity}
                </p>
                <b>Rs. {item.price * item.quantity}</b>
              </div>
            ))}
            <br />

            <hr className="h-px my-8 bg-gray-800 border border-black dark:bg-gray-800" />
            <div className="flex justify-between">
              <p>Total Price :</p>
              <b>
                {/* Calculate total price of all items in the cart */}
                Rs. {cartList.reduce((a, b) => a + b.price * b.quantity, 0)}
              </b>
            </div>
            <br />
            <Link to="/checkout">
              <Button color="success" size="md" className="w-full">
                Checkout
              </Button>
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default CartComponent;
